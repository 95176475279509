import {
  PageHeader,
  Result,
} from 'antd';
import Head from 'next/head';

import { useApplication } from '@/context/application';
import strings from '@/strings';

export default function Home(): JSX.Element {
  const { user, account } = useApplication();

  return (
    <>
      <Head>
        <title>{strings.growFlow} - {strings.home}</title>
      </Head>

      <PageHeader
        title={strings.home}
      />

      <Result
        status='success'
        title={`Hi ${user?.name}`}
        subTitle={<h3>Your account name is: {account?.name}</h3>}
      />
    </>
  );
}

